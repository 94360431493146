import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { colors } from '@mui/material';
import { color } from '@mui/system';
import CustomLegend from './CustomLegend';

const LineGraph = ({ data, yaxis }) => {
    // Extract unique departments from the data
    const departments = data.flatMap(entry =>
        entry.departments?.map(d => d.department)
    );

    const generateLineColor = department => {
        const colors = {
            [departments[0]]: '#344054',
            [departments[1]]: 'rgb(29, 176, 86)',
        };

        return colors[department] || 'rgb(0, 0, 0)';
    };

    const allCounts = data.flatMap(entry =>
        entry.departments?.map(d => d.count)
    );

    const minY = 0;
    const maxY = Math.ceil(Math.max(...allCounts) + 10);

    const uniqueDepartments = Array.from(new Set(departments));

    const calculateAverageScore = (departmentsData) => {
        const totalScore = departmentsData.reduce(
            (acc, dep) => acc + (dep.count || 0), 0
        );
        return (totalScore / departmentsData.length).toFixed(2);
    };

    return (
        <ResponsiveContainer width='100%' height={380}>
            <LineChart data={data}>
                {/* <CartesianGrid strokeDasharray='3 3' /> */}
                <XAxis
                stroke='#475467'
                    dataKey='auditDate'
                    tickFormatter={tick => moment(tick).format('MMM DD')}
                    label={{
                        value: 'Time Period',
                        position: 'insideBottom',
                        offset: -5,
                        fill: "#475467",
                        style: {
                            textAnchor: 'middle',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            color: 'red',
                        },
                    }}
                    tick={{ fontFamily: 'Inter, sans-serif', fontSize: '10px',  }}
                />
                <YAxis
                stroke='#475467'
                    label={{
                        value: yaxis,
                        angle: -90,
                        position: 'insideLeft',
                        offset: 25,
                        fill: "#475467",
                        style: {
                            textAnchor: 'middle',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                           
                        },
                    }}
                    domain={[minY, maxY]}
                    tick={{ fontFamily: 'Inter, sans-serif', fontSize: '10px' }}
                />
                <Tooltip />
                <Legend content={<CustomLegend />}
                    iconType='circle'
                    layout='horizontal'
                    verticalAlign='top'
                    align='left'
                    wrapperStyle={{
                        paddingBottom: 40,
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                />
                {uniqueDepartments?.map(department => (
                    <Line
                        key={department}
                        type='monotone'
                        dataKey={d =>
                            d.departments?.find(
                                dep => dep.department === department
                            )?.count || 0
                        }
                        name={department}
                        stroke={generateLineColor(department)}
                        strokeWidth={2}
                        dot={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineGraph;
