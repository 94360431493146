const sentimentAnalysis = [
    { x: '2024-09-01', positive: 89.56, negative: 75.45, neutral: 77.12 },
    { x: '2024-09-02', positive: 85.89, negative: 64.34, neutral: 75.56 },
    { x: '2024-09-03', positive: 80.56, negative: 74.01, neutral: 65.89 }, // Note: This date is outside the range
    { x: '2024-09-04', positive: 84.01, negative: 70.67, neutral: 68.45 }, // Note: This date is outside the range
    { x: '2024-09-05', positive: 87.34, negative: 70.23, neutral: 68.12 }, // Note: This date is outside the range
];
const positiveSentiment = [
    { name: 'Quick Resolution', value: 72 },
    { name: 'Professional Service', value: 68 },
    { name: 'Effective Solutions', value: 54 },
    { name: 'Knowledgeable Staff', value: 32 },
    { name: 'User-Friendly', value: 20 },
];

const negativeSentiment = [
    { name: 'Long Wait Times', value: 76 },
    { name: 'System Issues', value: 68 },
    { name: 'Billing Errors', value: 53 },
    { name: 'Poor Communication', value: 44 },
    { name: 'Repetitive Processes', value: 32 },
];

const stackBarChartHData = [
    { name: 'WhatsApp', type: 'positive', value: Math.round((30 * 364) / 798) }, // 14
    { name: 'WhatsApp', type: 'negative', value: Math.round((30 * 256) / 798) }, // 10
    { name: 'WhatsApp', type: 'neutral', value: Math.round((30 * 178) / 798) }, // 7
    { name: 'WhatsApp', type: 'total', value: 30 },

    {
        name: 'TripAdvisor',
        type: 'positive',
        value: Math.round((30 * 302) / 526),
    }, // 17
    {
        name: 'TripAdvisor',
        type: 'negative',
        value: Math.round((30 * 102) / 526),
    }, // 6
    {
        name: 'TripAdvisor',
        type: 'neutral',
        value: Math.round((30 * 12) / 526),
    }, // 1
    { name: 'TripAdvisor', type: 'total', value: 30 },

    { name: 'SnapChat', type: 'positive', value: Math.round((30 * 342) / 517) }, // 20
    { name: 'SnapChat', type: 'negative', value: Math.round((30 * 342) / 517) }, // 20
    { name: 'SnapChat', type: 'neutral', value: Math.round((30 * 233) / 517) }, // 14
    { name: 'SnapChat', type: 'total', value: 30 },

    { name: 'LinkedIn', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    { name: 'LinkedIn', type: 'negative', value: Math.round((30 * 256) / 454) }, // 17
    { name: 'LinkedIn', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'LinkedIn', type: 'total', value: 30 },

    { name: 'Instagram', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    {
        name: 'Instagram',
        type: 'negative',
        value: Math.round((30 * 256) / 454),
    }, // 17
    { name: 'Instagram', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'Instagram', type: 'total', value: 30 },

    { name: 'Twitter', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    { name: 'Twitter', type: 'negative', value: Math.round((30 * 256) / 454) }, // 17
    { name: 'Twitter', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'Twitter', type: 'total', value: 30 },

    { name: 'Facebook', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    { name: 'Facebook', type: 'negative', value: Math.round((30 * 256) / 454) }, // 17
    { name: 'Facebook', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'Facebook', type: 'total', value: 30 },
];

const pieChartData = [
    { name: 'Positive', value: '15' },
    { name: 'Negative', value: '6' },
    { name: 'Neutral', value: '9' },
];

const positiveWordData = [
    { text: 'Helped', size: 100 },
    { text: 'Resolved', size: 95 },
    { text: 'Friendly', size: 90 },
    { text: 'Easy', size: 88 },
    { text: 'Efficient', size: 85 },
    { text: 'Supportive', size: 83 },
    { text: 'Quick', size: 80 },
    { text: 'Knowledgeable', size: 78 },
    { text: 'Professional', size: 75 },
    { text: 'Satisfied', size: 73 },
    { text: 'Courteous', size: 70 },
    { text: 'Excellent', size: 68 },
    { text: 'Clear', size: 65 },
    { text: 'Responsive', size: 63 },
    { text: 'Smooth', size: 60 },
];

const negativeWordData = [
    { text: 'Waiting', size: 100 },
    { text: 'Issue', size: 95 },
    { text: 'Frustrating', size: 90 },
    { text: 'Unclear', size: 85 },
    { text: 'Problem', size: 80 },
    { text: 'Delay', size: 75 },
    { text: 'Confusing', size: 70 },
    { text: 'Repeated', size: 65 },
    { text: 'Disconnected', size: 60 },
    { text: 'Billing', size: 55 },
    { text: 'Unresolved', size: 50 },
    { text: 'Complicated', size: 45 },
    { text: 'Poor', size: 40 },
    { text: 'Miscommunication', size: 35 },
    { text: 'Slow', size: 30 },
];

const positiveTweetData = [
    'The representative was very helpful and resolved my issue quickly.',
    'I appreciated the clear communication and the professional approach.',
    'The process was easy to understand and follow, making my experience pleasant.',
    'I was impressed by the efficiency of the team in handling my query.',
    'Customer service is excellent, and they always provide timely support.',
];

const negativeTweetData = [
    'I had to wait too long to speak to a representative, which was frustrating.',
    'There were repeated issues with the billing process that were not resolved in a timely manner.',
    'Communication was unclear, leading to confusion about the next steps.',
    'The system kept disconnecting me, making it difficult to get my problem resolved.',
    'The process was too complicated and required multiple calls to get a resolution.',
];

const ConduentData = {
    negativeSentiment,
    negativeTweetData,
    negativeWordData,
    pieChartData,
    positiveSentiment,
    positiveTweetData,
    positiveWordData,
    sentimentAnalysis,
    stackBarChartHData,
};

export default ConduentData;
