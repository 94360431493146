const sentimentAnalysis = [
    { x: '2024-09-01', positive: 83.56, negative: 71.45, neutral: 67.12 },
    { x: '2024-09-02', positive: 80.89, negative: 74.34, neutral: 65.56 },
    { x: '2024-09-03', positive: 80.56, negative: 74.01, neutral: 65.89 }, // Note: This date is outside the range
    { x: '2024-09-04', positive: 84.01, negative: 70.67, neutral: 68.45 }, // Note: This date is outside the range
    { x: '2024-09-05', positive: 84.34, negative: 70.23, neutral: 68.12 }, // Note: This date is outside the range
];

const positiveSentiment = [
    { name: 'Fast Results', value: 72 },
    { name: 'Easy Scheduling', value: 68 },
    { name: 'User-Friendly Device', value: 54 },
    { name: 'Professional Support', value: 32 },
    { name: 'Accurate Reports', value: 20 },
];

const negativeSentiment = [
    { name: 'Device Issues', value: 76 },
    { name: 'Long Wait Times', value: 68 },
    { name: 'Billing Confusion', value: 53 },
    { name: 'Unclear Instructions', value: 44 },
    { name: 'Technical Glitches', value: 32 },
];

const stackBarChartHData = [
    { name: 'WhatsApp', type: 'positive', value: Math.round((30 * 364) / 798) }, // 14
    { name: 'WhatsApp', type: 'negative', value: Math.round((30 * 256) / 798) }, // 10
    { name: 'WhatsApp', type: 'neutral', value: Math.round((30 * 178) / 798) }, // 7
    { name: 'WhatsApp', type: 'total', value: 30 },

    {
        name: 'TripAdvisor',
        type: 'positive',
        value: Math.round((30 * 302) / 526),
    }, // 17
    {
        name: 'TripAdvisor',
        type: 'negative',
        value: Math.round((30 * 102) / 526),
    }, // 6
    {
        name: 'TripAdvisor',
        type: 'neutral',
        value: Math.round((30 * 12) / 526),
    }, // 1
    { name: 'TripAdvisor', type: 'total', value: 30 },

    { name: 'SnapChat', type: 'positive', value: Math.round((30 * 342) / 517) }, // 20
    { name: 'SnapChat', type: 'negative', value: Math.round((30 * 342) / 517) }, // 20
    { name: 'SnapChat', type: 'neutral', value: Math.round((30 * 233) / 517) }, // 14
    { name: 'SnapChat', type: 'total', value: 30 },

    { name: 'LinkedIn', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    { name: 'LinkedIn', type: 'negative', value: Math.round((30 * 256) / 454) }, // 17
    { name: 'LinkedIn', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'LinkedIn', type: 'total', value: 30 },

    { name: 'Instagram', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    {
        name: 'Instagram',
        type: 'negative',
        value: Math.round((30 * 256) / 454),
    }, // 17
    { name: 'Instagram', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'Instagram', type: 'total', value: 30 },

    { name: 'Twitter', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    { name: 'Twitter', type: 'negative', value: Math.round((30 * 256) / 454) }, // 17
    { name: 'Twitter', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'Twitter', type: 'total', value: 30 },

    { name: 'Facebook', type: 'positive', value: Math.round((30 * 20) / 454) }, // 1
    { name: 'Facebook', type: 'negative', value: Math.round((30 * 256) / 454) }, // 17
    { name: 'Facebook', type: 'neutral', value: Math.round((30 * 178) / 454) }, // 12
    { name: 'Facebook', type: 'total', value: 30 },
];

const pieChartData = [
    { name: 'Positive', value: '18' },
    { name: 'Negative', value: '7' },
    { name: 'Neutral', value: '5' },
];

const positiveWordData = [
    { text: 'Efficient', size: 100 },
    { text: 'Helpful', size: 95 },
    { text: 'Accurate', size: 90 },
    { text: 'Simple', size: 88 },
    { text: 'Quick', size: 85 },
    { text: 'Reliable', size: 83 },
    { text: 'Professional', size: 80 },
    { text: 'Smooth', size: 78 },
    { text: 'Effective', size: 75 },
    { text: 'Friendly', size: 73 },
    { text: 'Convenient', size: 70 },
    { text: 'Clear', size: 68 },
    { text: 'Fast', size: 65 },
    { text: 'Great Service', size: 63 },
    { text: 'Quality', size: 60 },
];

const negativeWordData = [
    { text: 'Confusing', size: 100 },
    { text: 'Delay', size: 95 },
    { text: 'Difficult', size: 90 },
    { text: 'Long Wait', size: 88 },
    { text: 'Unclear', size: 85 },
    { text: 'Issue', size: 83 },
    { text: 'Problem', size: 80 },
    { text: 'Complicated', size: 78 },
    { text: 'Poor Support', size: 75 },
    { text: 'Error', size: 73 },
    { text: 'Troubleshoot', size: 70 },
    { text: 'Frustrating', size: 68 },
    { text: 'Incorrect', size: 65 },
    { text: 'Cancelled', size: 60 },
    { text: 'Expensive', size: 55 },
];

const positiveTweetData = [
    'The test results were delivered quickly and accurately.',
    'Scheduling a test was very straightforward and easy.',
    'Customer service was friendly and professional.',
    'The device was simple to use with clear instructions.',
    'Overall experience was smooth, from booking to receiving results.',
];

const negativeTweetData = [
    'There were delays in getting test results back.',
    'Instructions provided with the device were not clear enough.',
    'Had trouble reaching customer support for follow-up questions.',
    'Faced issues with billing and insurance claims.',
    'The device malfunctioned during testing, causing inconvenience.',
];

const VirtuOxData = {
    negativeSentiment,
    negativeTweetData,
    negativeWordData,
    pieChartData,
    positiveSentiment,
    positiveTweetData,
    positiveWordData,
    sentimentAnalysis,
    stackBarChartHData,
};

export default VirtuOxData;
