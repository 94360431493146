import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import cloud from "d3-cloud";

import styles from "../word_cloud_chart/WordCloudChart.module.css";
import { useResize } from "../use_resize/useResize";
import useElementView from "../use_element_view/useElementView";

const WordCloud = ({ data, colorRange, title, height }) => {
  const [svgContainer, isInView] = useElementView();
  const width = useResize(svgContainer, 0);

  const svgRef = useRef(null);

  useEffect(() => {
    if (!width || !data || !isInView) return;
    const margin = { top: 20, right: 30, bottom: 40, left: 0 };

    // setting the svg container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const everything = svg.selectAll("*");
    everything.remove();

    const color = d3
      .scaleOrdinal()
      .domain([10, 15, 20, 25, 35, 40, 100])
      .range(colorRange);

    const layout = cloud()
      .size([width, height])
      .words(data?.map((d) => ({ text: d.text, size: d.size })))
      .padding(5)
      .rotate(0)
      .spiral("archimedean")
      .fontSize((d) => d.size / 2.8)
      .on("end", draw);

    layout.start();

    function draw(data) {
      d3.select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2},${height / 2})`)
        .selectAll("text")
        .data(data)
        .enter()
        .append("text")
        .style("font-size", (d) => `${d.size}px`)
        .style("fill", (d) => color(d.size))
        .attr("text-anchor", "middle")
        .attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ")";
        })
        .text(function (d) {
          return d.text;
        });
    }
  }, [width, height, data, isInView]);

  return (
    <div ref={svgContainer} className={styles.container}>
      <svg ref={svgRef} className={styles.svgContainer} />
    </div>
  );
};

export default WordCloud;
