import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import SentimentBarGraph from '../components/SentimentBarGraph/SentimentBarGraph';
import LineGraph from '../components/LineGraph/LineGraph';
import * as AuthSelector from '../../store/auth/selectors';
import { useSelector } from 'react-redux';

const AgentDrillDown = () => {
    const location = useLocation();
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const navigate = useNavigate();
    const { name, type } = location.state || {};
    const handleBackClick = () => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        pathSegments.pop(); // Remove the last segment
        const parentPath = `/${pathSegments.join('/')}`;
        navigate(parentPath);
    };
    const department1 = orgStrId == '4056-250-A1EA' ? "Higbee NA IB Health  " : "EBT Higbee NA IB Direct Debit ";
    const department2 = orgStrId == '4056-250-A1EA' ? "Cebu Inbound Scheduling" : "EBT Higbee NA IB Direct Debit ";

    const data = {
        TotalAuditCount: '99.35%',
        totalAuditsAccepted: 34,
        totalAuditsPending: 8,
        totalRebuttals: 8,
        averageAuditScore: '88%',
        averageAHT: '3min 37sec',
    };

    return (
        <div className='page-wrapper no_overflow_x'>
            <div className='content pb-0'>
                {/* Back Button */}
                <button onClick={handleBackClick} className='back-button'>
                    Back
                </button>
                <div
                    style={{
                        marginTop: '45px',
                        marginBottom: '20px',
                        fontSize: '38px',
                        fontWeight: 'bold',
                        color: 'rgb(78, 91, 166)',
                    }}>
                    {name ? `${type} Name: ${name}` : type}
                </div>
                <div className='row' style={{ marginTop: '20px' }}>
                    <div className='app-container-demo'>
                        <div className='dashboard'>
                            <div className='small-cards'>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Avg Audit Score - By Agent
                                                </h3>
                                            </div>
                                            <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Audit Count - By Agent
                                                </h3>
                                            </div>
                                            <div className='scalar-value-container'>
                                                <h1
                                                    className='scalar-value'
                                                    style={{
                                                        color: `rgb(
                                                            39,
                                                            227,
                                                            118
                                                        )`,
                                                    }}>
                                                    7
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Fatal Count - By Agent
                                                </h3>
                                            </div>
                                            <div className='scalar-value-container'>
                                                <h1
                                                    className='scalar-value'
                                                    style={{
                                                        color: 'var(--negative)',
                                                    }}>
                                                    {data?.totalAuditsPending}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='large-cards'>
                                <div className='large-card'>
                                    <div className='card-title'>
                                        Audit Scores Trend
                                    </div>
                                    <div className='large-card-content'>
                                        <LineGraph
                                            data={[
                                                {
                                                    auditDate: '2024-09-01',
                                                    departments: [
                                                        {
                                                            department:
                                                                department1,
                                                            count: 2,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-02',
                                                    departments: [
                                                        {
                                                            department:
                                                                department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                                department2,
                                                            count: 4,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-03',
                                                    departments: [
                                                        {
                                                            department:
                                                                department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                                department2,
                                                            count: 4,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-04',
                                                    departments: [
                                                        {
                                                            department:
                                                                department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                                department2,
                                                            count: 4,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-05',
                                                    departments: [
                                                        {
                                                            department:
                                                                department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                                department2,
                                                            count: 4,
                                                        },
                                                    ],
                                                },
                                            ]}
                                            yaxis={'Count'}
                                        />
                                    </div>
                                </div>

                                <div className='large-card'>
                                    <div className='card-title'>
                                        Number of Audit Count - Trend
                                    </div>
                                    <div className='large-card-content'>
                                        <LineGraph
                                            data={[
                                                {
                                                    auditDate: '2024-09-01',
                                                    departments: [
                                                        {
                                                            department:
                                                                department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                                department2,
                                                            count: 3,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-02',
                                                    departments: [
                                                        {
                                                            department:
                                                            department1,
                                                            count: 3,
                                                        },
                                                        {
                                                            department:
                                                            department2,
                                                            count: 4,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-03',
                                                    departments: [
                                                        {
                                                            department:
                                                            department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                            department2,
                                                            count: 4,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-04',
                                                    departments: [
                                                        {
                                                            department:
                                                            department1,
                                                            count: 3,
                                                        },
                                                        {
                                                            department:
                                                            department2,
                                                            count: 5,
                                                        },
                                                    ],
                                                },
                                                {
                                                    auditDate: '2024-09-05',
                                                    departments: [
                                                        {
                                                            department:
                                                            department1,
                                                            count: 2,
                                                        },
                                                        {
                                                            department:
                                                            department2,
                                                            count: 2,
                                                        },
                                                    ],
                                                },
                                            ]}
                                            yaxis={'Count'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>{' '}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentDrillDown;
