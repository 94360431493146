import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import CustomLegend from './CustomLegend';

// Sample data
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <p className="desc">{`Avg. Quality Score: ${payload[0].value}%`}</p>
                <p className="desc">{`Count of Audits: ${payload[0].payload[`${payload[0].dataKey}Audits`]}`}</p>
            </div>
        );
    }
    return null;
};

const QualityScoreTrend = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={380} >
            <LineChart data={data} margin={{ top: 30, right: 30, left: 20, bottom: 20 }}>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="time" label={{ value: ' Time Interval', position: 'insideBottom', offset: -15,  }} >
                </XAxis>
                <YAxis>
                    <Label angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} >
                        Avg. Quality Score
                    </Label>
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" align="center" height={36} content={<CustomLegend />} />
                {/* <Line type="monotone" dataKey="myPerformance" name="My Performance" stroke=/> */}
                <Line type="monotone" dataKey="team" name="Team" stroke="#23CE6B" />
                <Line type="monotone" dataKey="process" name="Process" stroke="#05603A" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default QualityScoreTrend;
