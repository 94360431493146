import { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';

import styles from './BarChartH.module.css';

import { useResize } from '../use_resize/useResize';
import useElementView from '../use_element_view/useElementView';

const BarChartH = ({ data, height, colors = ['#32D583', '#6CE9A6', '#A6F4C5', '#D1FADF'], textColor, paddLeft }) => {
    const [svgContainer, isInView] = useElementView();
    const svgRef = useRef(null);

    // sort data
    data?.sort(function (b, a) {
        return a.value - b.value;
    });

    const width = useResize(svgContainer, 0);

    useEffect(() => {
        if (!width || !data || !isInView) return;

        const margin = { top: 2, right: 0, bottom: 2, left: 0 };

        // setting the svg container
        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height);

        // clear all previous content on refresh
        const everything = svg.selectAll('*');
        everything.remove();

        // setting the scales
        const xScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, d => d.value)])
            // .domain([0, 105])
            .range([margin.left, width - margin.right]);

        const yScale = d3
            .scaleBand()
            .domain(data.map(d => d.name))
            .range([margin.top, height - margin.bottom]);

        const container = svg
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        // setting the color scale
        const colorScale = d3
            .scaleLinear()
            .domain([d3.min(data, d => d.value), d3.max(data, d => d.value)])
            .range(colors);

        // setting the bar
        container
            .selectAll()
            .data(data)
            .enter()
            .append('rect')
            .attr('x', margin.left)
            .attr('y', d => yScale(d.name))
            .attr('height', yScale.bandwidth())
            .attr('width', 0)
            .attr('fill', d => colorScale(d.value))
            .on('mouseover', function (e, data) {
                tooltip
                    .html(`<div>${data.value}</div>`)
                    .style('visibility', 'visible');
            })
            .on('mousemove', function (e) {
                tooltip
                    .style('top', e.offsetY - 60 + 'px')
                    .style('left', e.offsetX + 40 + 'px');
            })
            .on('mouseout', function () {
                tooltip.html(``).style('visibility', 'hidden');
            })
            .transition()
            .duration(1500)
            .attr('x', xScale(0))
            .attr('width', d => xScale(d.value));

        // Add labels inside bars
        container
            .selectAll('.bar')
            .data(data)
            .enter()
            .append('text')
            .attr('class', textColor ? styles.barLabel2 : styles.barLabel)
            .attr('x', xScale(4))
            .attr('y', d => yScale(d.name) + yScale.bandwidth() / 2)
            .attr('dx', paddLeft)
            .attr('dy', '0.35em')
            .text(d => d.name)
            .style('padding', '10px');

        const tooltip = d3
            .select(svgContainer.current)
            .append('div')
            .attr('class', styles.tooltip);
    }, [data, width, height, isInView]);

    return (
        <div ref={svgContainer} className={styles.container}>
            <svg ref={svgRef} className={styles.svgContainer} />
        </div>
    );
};

export default BarChartH;
