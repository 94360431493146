import { useRef, useState, useEffect } from 'react';

const useElementView = () => {
    // Intersection observer implementation
    const [isInView, setIsInView] = useState(false);
    const [hasIntersected, setHasIntersected] = useState(false);
    const svgContainer = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                const [entry] = entries;
                if (entry.isIntersecting && !hasIntersected) {
                    setIsInView(true);
                }
            },
            { threshold: 0.9 }
        );

        if (svgContainer.current) {
            observer.observe(svgContainer.current);
        }

        return () => {
            if (svgContainer.current) {
                observer.unobserve(svgContainer.current);
            }
        };
    }, []);

    return [svgContainer, isInView];
};

export default useElementView;
