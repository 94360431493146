import { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import format from 'date-fns/format';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangeFunction } from '../../../../CommonLogic';

const DateRangePickerComp = ({
    defaultRange = DateRangeFunction(0, 6),
    onDateChange = e => e,
    disabled = false,
}) => {
    // date state
    const [range, setRange] = useState([defaultRange]);
    const [open, setOpen] = useState(false);
    const handleChange = e => {
        setRange([e]);
        if (e && e.startDate != e.endDate) {
            e = {
                ...e,
                endDate: new Date(
                    new Date(e.endDate).setHours('23', '59', '59')
                ),
            };
            setOpen(false);
            onDateChange(e);
        }
    };

    // get the target element to toggle
    const refOne = useRef(null);

    useEffect(() => {
        document.addEventListener('keydown', hideOnEscape, true);
        document.addEventListener('click', hideOnClickOutside, true);
    }, []);

    // hide dropdown on ESC press
    const hideOnEscape = e => {
        if (e.key === 'Escape') {
            setOpen(false);
        }
    };

    // Hide dropdown on outside click
    const hideOnClickOutside = e => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false);
        }
    };

    return (
        <div className='calendarWrap'>
            <input
                value={`${format(range[0]?.startDate, 'dd-MMM-yyyy')} to ${format(
                    range[0]?.endDate,
                    'dd-MMM-yyyy'
                )}`}
                readOnly
                className='inputBox form-control'
                onClick={() => setOpen(open => !open)}
                disabled={disabled}
                style={{ background: disabled ? '#e9ecef' : '' }}
            />

            <div ref={refOne}>
                {open && (
                    <DateRangePicker
                        onChange={item => {
                            handleChange(item.selection);
                        }}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        months={2}
                        rangeColors='#072b15'
                        color='#072b15'
                        direction='horizontal'
                        className='calendarElement'
                        inputRanges={[]}
                    />
                )}
            </div>
        </div>
    );
};

export default DateRangePickerComp;
