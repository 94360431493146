import React from "react";

import styles from "./Container.module.css";

const Container = ({ title = "", legends = [], colors = [], body }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{title}</h4>
        <div className={styles.legends_container}>
          {legends.map((legend, index) => (
            <div>
              <span
                style={{
                  backgroundColor: colors[index],
                }}
              ></span>
              <p>{legend}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.body}>{body}</div>
    </div>
  );
};

export default Container;
