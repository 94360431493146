import React, { useEffect, useState } from 'react';
import './App.css'; // Import the global styles
import SortableTable from './components/SortableTable/SortableTable';
import LineGraph from './components/LineGraph/LineGraph';
import SentimentBarGraph from './components/SentimentBarGraph/SentimentBarGraph';
import { useSelector } from 'react-redux';
import { loggedInUser } from '../store/auth/selectors';
import VirtuOxDataGraph from './components/Data/VirtuOx/dataGraph';
import ConduentDataGraph from './components/Data/Conduent/dataGraph';
import * as AuthSelector from '../store/auth/selectors';
import OverallSentiment from './components/overall_sentiment/OverallSentiment';

function DemoChart() {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const userInfo = useSelector(loggedInUser);
    const {
        agentDataSentiment,
        qaDataSentiment,
        dailyAuditCountsByDepartment,
        fatalAuditCounts,
        overallSentimentData,
        customerSentimentData,
        agentSentimentData,
        datewiseSentimentData,
    } = (orgStrId == 'E6DA-475-BC10' ? VirtuOxDataGraph : ConduentDataGraph);
    const QAData = {
        TotalAuditCount: 125,
        TotalAuditsAccepted: 92,
        TotalAuditsPending: 13,
        TotalRebuttalsPending: 20,
        AverageQuaityScore: '92%',
        NumberofUniqueAgentsEvaluated: 13,
        FatalCalls: 7,
        PercentageFatalCalls: 9,
        AverageEvaluationTime: '26 secs',
        AverageAHT: '1 min 44 secs'
    };

    const AGENTData = {
        TotalAuditCount: '10',
        TotalAudits: 10,
        TotalFatals: 1,
    };

    const [data, setData] = useState({});
    useEffect(() => {
        setData(userInfo.role == 'Agent' ? AGENTData : QAData);
    }, [userInfo]);

    return (
        <div className='page-wrapper no_overflow_x'>
            <div className='content pb-0'>
                <div className='row'>
                    <div className='app-container-demo'>
                        <div className='dashboard'>
                            {/* new card style start */}
                            <div className='analytics_card_area' style={{display: 'none'}}>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-lg-4 analytics_card_box'>
                                            <div className='analytics_card'>
                                                <h3 className='scalar-title'>
                                                    Total Audit Count: <span className='scalar-value'>
                                                        {data?.TotalAuditCount}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    Audits Accepted: <span className='scalar-value'>
                                                        {data?.TotalAuditsAccepted}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    Audits Pending: <span className='scalar-value'>
                                                        {data?.TotalAuditsPending}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    Rebuttals Pending: <span className='scalar-value'>
                                                        {data?.TotalRebuttalsPending}
                                                    </span>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 analytics_card_box'>
                                            <div className='analytics_card'>
                                                <h3 className='scalar-title'>
                                                    Average Quaity Score: <span className='scalar-value'>
                                                        {data?.AverageQuaityScore}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    Average Evaluation Time: <span className='scalar-value'>
                                                        {data?.AverageEvaluationTime}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    Average AHT: <span className='scalar-value'>
                                                        {data?.AverageAHT}
                                                    </span>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 analytics_card_box'>
                                            <div className='analytics_card'>
                                                <h3 className='scalar-title'>
                                                    Unique Agents Evaluated: <span className='scalar-value'>
                                                        {data?.NumberofUniqueAgentsEvaluated}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    Fatal Calls: <span className='scalar-value'>
                                                        {data?.FatalCalls}
                                                    </span>
                                                </h3>
                                                <h3 className='scalar-title'>
                                                    % Fatal Calls: <span className='scalar-value'>
                                                        {data?.PercentageFatalCalls}%
                                                    </span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* new card style end */}
                            <div className='small-cards'>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Total Audit Count
                                                    <h4 className='scalar-value'>
                                                        {data?.TotalAuditCount}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Total Audits Accepted
                                                     <h4 className='scalar-value'>
                                                        {data?.TotalAuditsAccepted}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Total Audits Pending
                                                    <h4 className='scalar-value'>
                                                        {data?.TotalAuditsPending}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Total Rebuttals Pending
                                                    <h4 className='scalar-value'>
                                                        {data?.TotalRebuttalsPending}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Average Quaity Score
                                                    <h4 className='scalar-value'>
                                                        {data?.AverageQuaityScore}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Unique Agents Evaluated 
                                                    <h4 className='scalar-value'>
                                                        {data?.NumberofUniqueAgentsEvaluated}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Fatal Calls 
                                                    <h4 className='scalar-value'>
                                                        {data?.FatalCalls}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    % Fatal Calls 
                                                    <h4 className='scalar-value'>
                                                        {data?.PercentageFatalCalls}%
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAuditCount}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Average Evaluation Time 
                                                    <h4 className='scalar-value'>
                                                        {data?.AverageEvaluationTime}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalAudits}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Average AHT 
                                                    <h4 className='scalar-value'>
                                                        {data?.AverageAHT}
                                                    </h4>
                                                </h3>
                                            </div>
                                            {/* <div className='scalar-value-container'>
                                                <h1 className='scalar-value'>
                                                    {data?.TotalFatals}
                                                </h1>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='large-cards'>
                                <div className='large-card'>
                                    <div className='card-title'>
                                        Agent Wise Average Audit Score
                                    </div>
                                    <div className='large-card-content'>
                                        <SortableTable
                                            data={agentDataSentiment}
                                            type='Agent'
                                            title='Agent Name'
                                        />
                                    </div>
                                </div>

                                <div className='large-card'>
                                    <div className='card-title'>
                                        QA Wise Average Audit Score
                                    </div>
                                    <div className='large-card-content'>
                                        <SortableTable
                                            data={qaDataSentiment}
                                            type='QA'
                                            title='QA Name'
                                        />
                                    </div>
                                </div>

                                <div className='large-card title_legend_line'>
                                    <div className='card-title'>
                                        Audit Trend
                                    </div>
                                    <div className='large-card-content'>
                                        <LineGraph
                                            data={dailyAuditCountsByDepartment}
                                            yaxis={'Number of Audits'}
                                        />
                                    </div>
                                </div>

                                <div className='large-card title_legend_line'>
                                    <div className='card-title'>
                                        Fatal Audits Trend
                                    </div>
                                    <div className='large-card-content'>
                                        <LineGraph
                                            data={fatalAuditCounts}
                                            yaxis='Number of Fatal Audits'
                                        />
                                    </div>
                                </div>

                                <></>

                                {userInfo && userInfo.role !== 'Agent' && (
                                    <>
                                        <div className='large-card'>
                                            <div className='card-title'>
                                                Overall Sentiment
                                            </div>
                                            <div className='large-card-content'>
                                                <SentimentBarGraph
                                                    data={overallSentimentData}
                                                    yaxis='Overall Sentiment'
                                                />
                                            </div>
                                        </div>

                                        <div className='large-card title_legend_line'>
                                            <div className='card-title'>
                                                Customer Sentiment
                                            </div>
                                            <div className='large-card-content'>
                                                <SentimentBarGraph
                                                    data={customerSentimentData}
                                                    yaxis='Customer Sentiment'
                                                />
                                            </div>
                                        </div>

                                        <div className='large-card title_legend_line'>
                                            <div className='card-title'>
                                                Agent Sentiment
                                            </div>
                                            <div className='large-card-content'>
                                                <SentimentBarGraph
                                                    data={agentSentimentData}
                                                    yaxis='Agent Sentiment'
                                                />
                                            </div>
                                        </div>

                                        <div className='large-card title_legend_line'>
                                            <div className='card-title'>
                                                Datewise Sentiment Analysis
                                            </div>
                                            <div className='large-card-content'>
                                                <SentimentBarGraph
                                                    data={datewiseSentimentData}
                                                    yaxis='Sentiment Count'
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {userInfo && userInfo.role !== 'Agent' && (
                                <OverallSentiment />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemoChart;
