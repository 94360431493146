import styles from './AnalyticsTable.module.css';
const AnalyticsTable = ({
    header,
    data,
    height,
    width = '100%',
    thead,
    survey,
}) => {
    return (
        <div
            className={styles.table_container}
            style={{ height: height, width: width }}>
            <div className={styles.table_header}>
                <h6>{header}</h6>
            </div>
            <div className='table_style_comman'>
                <table className='table table-border'>
                    <thead>
                        <tr className={styles.table_header}>
                            {thead?.map((item, index) => (
                                <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => {
                            return (
                                <tr key={index} className={styles.table_body}>
                                    {thead?.map((th, idx) => (
                                        <td key={idx} style={{ textAlign: 'left' }}>
                                            {item[
                                                th.toLowerCase().split(" ").join("_")
                                            ] || ''}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default AnalyticsTable;
