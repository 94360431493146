import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const QualityLineChart = ({ data,chartFor }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={chartFor === "Quality_Score" ? "time" : "Time_Period"} label={{ value: chartFor === "Quality_Score" ? 'Time Interval' : "Time Period" , position: 'insideBottom', offset: -5 }} />
        <YAxis label={{ value:chartFor === "Quality_Score" ? 'Avg Quality Score' : "Number of Audits", angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Line type="monotone" dataKey={chartFor === "Quality_Score" ? "avgQualityScore" : "NumberofAudits"} stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default QualityLineChart;
