import { useState, useEffect, useRef } from "react";
import * as d3 from "d3";

import styles from "./MultilineChart.module.css";
import { useResize } from "../use_resize/useResize";
import useElementView from "../use_element_view/useElementView";

const MultilineChart = ({ data, height, legends, color }) => {
  const [svgContainer, isInView] = useElementView();
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);

  const lineNames = [];
  legends?.map((legend) =>
    lineNames.push(legend.replace(" ", "")?.toLowerCase())
  );

  const parseDate = d3.timeParse("%Y-%m-%d");

  data = data?.map((d) => {
    const newData = { x: parseDate(d.x) };

    lineNames.forEach((name) => {
      newData[name] =
        d[name] == 0 ? parseInt(d[name]) : parseFloat(d[name].toFixed(1));
    });

    return newData;
  });

  const maxValue =
    data &&
    Math.max(...lineNames.map((lineName) => d3.max(data, (d) => d[lineName])));
  const minValue =
    data &&
    Math.min(...lineNames.map((lineName) => d3.min(data, (d) => d[lineName])));

  const width = useResize(svgContainer, 0);

  const formatDate = (value) =>
    value.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

  useEffect(() => {
    if (!width || !data || !isInView) return;
    const margin = { top: 10, right: 40, bottom: 50, left: 20 };

    // setting the svg container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // clear all previous content on refresh
    const everything = svg.selectAll("*");
    everything.remove();

    // set the scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.x))
      .range([margin.left, width - margin.right])
      .nice();

    const yScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([height - margin.bottom, margin.top]);

    const container = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // setting the axis
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(5)
      .tickSize(0)
      .tickPadding(20)
      .tickFormat(d3.timeFormat("%b, %y"));
    const yAxis = d3.axisLeft(yScale).tickSize(0).tickPadding(10);

    container
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(xAxis)
      .call((g) => g.select(".domain").remove())
      .style("font-size", "12px");

    container
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("x2", width)
          .attr("stroke-opacity", 0.1)
      )
      .style("font-size", "12px");

    // define the lines
    const lines = [];
    lineNames?.map((lineName, index) =>
      lines.push(
        d3
          .line()
          .x((d) => xScale(d.x))
          .y((d) => yScale(d[lineNames[index]]))
        // .curve(d3.curveMonotoneX)
        // .curve(d3.curveCatmullRom.alpha(0.4))
      )
    );

    // add all the paths
    const linePaths = [];
    lines?.map((line, index) =>
      linePaths.push(
        container
          .append("path")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", color[index])
          .attr("d", lines[index])
      )
    );

    const linePathLengths = [];
    linePaths?.map((linePath, index) => {
      const linePathLength = linePaths[index].node().getTotalLength();
      linePathLengths.push(
        linePath
          .attr("stroke-dashoffset", linePathLength)
          .attr("stroke-dasharray", linePathLength)
          .transition()
          .duration(1500)
          .attr("stroke-dashoffset", 0)
      );
    });

    // Mouseover and Mouseout event handlers
    svg
      .on("mouseover", function () {
        dots.map((dot, index) => dot.style("visibility", "visible"));
      })
      .on("mouseout", function () {
        dots.map((dot, index) => dot.style("visibility", "hidden"));
      });

    const tooltip = d3
      .select(svgContainer.current)
      .append("div")
      .attr("class", styles.tooltip);

    // // Dots (Circles)
    const dots = [];
    lines?.map((line, index) =>
      dots.push(
        container
          .selectAll(`dotCircle${index}`)
          .data(data)
          .enter()
          .append("circle")
          .attr("cx", (d) => xScale(d.x))
          .attr("cy", (d) => yScale(d[lineNames[index]]))
          .attr("r", 3)
          .attr("fill", color[index])
          .style("visibility", "hidden")
      )
    );

    const tooltipText = (d) => {
      let tooltipString = `<div>Date: ${formatDate(d.x)}</div>`;
      lineNames?.map(
        (lineName, index) =>
          (tooltipString += `<div>${legends[index]}: ${d[lineName]}</div>`)
      );
      return tooltipString;
    };

    // Mouseover and Mouseout event handlers for dots
    dots.map((dot, index) =>
      dot
        .on("mouseover", function (e, d) {
          e.preventDefault();
          tooltip.style("visibility", "visible");
          tooltip
            .html(tooltipText(d))
            .style(
              "left",
              `${
                e.offsetX > width - margin.right - 100
                  ? e.offsetX - 100
                  : e.offsetX - 25
              }px`
            )
            .style("top", `${e.offsetY - lineNames?.length * 60}px`);
        })
        .on("mouseout", function (e) {
          e.preventDefault();
          tooltip.style("visibility", "hidden");
        })
    );
  }, [data, width, height, isInView]);

  return (
    <div ref={svgContainer} className={styles.container}>
      <svg ref={svgRef} className={styles.svgContainer} />
      <div ref={tooltipRef} className={styles.tooltip}></div>
    </div>
  );
};

export default MultilineChart;
