import React, { useEffect, useState } from 'react';
import './App.css'; // Import the global styles
import { addDays } from 'date-fns';
import VirtuOxDataGraph from './components/Data/VirtuOx/dataGraph';
import { useSelector } from 'react-redux';
import AnalyticsTable from './components/analytics_table/AnalyticsTable';
import * as AuthSelector from '../store/auth/selectors';
import { loggedInUser } from '../store/auth/selectors';
import Container from './components/container/Container';
import BarChartH from './components/barchart_horizontal/BarChartH';
import QualityLineChart from './components/LineGraph/QualityLineChart';
import QualityScoreTrend from './components/LineGraph/CustomLineGraph';
import DateRangePickerComp from '../views/modules/social/AuditSheet/DateRangePickerComp';
function DemoChart() {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const userInfo = useSelector(loggedInUser);
    const {
        key_performance_metrics,
        key_performance_metrics_agent ,
        key_performance_metrics_qamanager,
        key_performance_metrics_tl,
        monthlyCSATTrend,
        other_performance_metrics,
        other_performance_metrics_agent,
        other_performance_metrics_tl,
        other_performance_metrics_qamanager,
        agent_leaderboard,
        agent_leaderboard_tl,
        qa_wise_average_audit_score,
        qa_wise_average_audit_score_tl,
        performance_metrics,
        surging_topic,
        agentDataSentiment,
        qaDataSentiment,
    } = VirtuOxDataGraph

    const QAData = {
        TotalAuditCount: 30,
        TotalAuditsAccepted: 21,
        TotalAuditsPending: 5,
        TotalRebuttalsPending: 4,
        AverageQuaityScore: '92%',
        NumberofUniqueAgentsEvaluated: 8,
        FatalCalls: 3,
        PercentageFatalCalls: 8,
        AverageEvaluationTime: '32 secs',
        AverageAHT:'2 min 37 secs',
        TotalAuditsPendingtobeAcknowledged: 0
    };

    const QualityManagerData = {
        TotalAuditCount: 30,
        TotalAuditsAccepted: 21,
        TotalAuditsPending: 5,
        TotalRebuttalsPending: 4,
        AverageQuaityScore: '92%',
        NumberofUniqueAgentsEvaluated: 8,
        FatalCalls: 3,
        PercentageFatalCalls: 8,
        AverageEvaluationTime: '32 secs',
        AverageAHT:'2 min 37 secs',
        TotalAuditsPendingtobeAcknowledged: 0
    };

    const AGENTData = {
        lob: '30',
        totalAuditsEvaluatedon: 30,
        totalAuditsAccepted: 20,
        totalAuditsPending: 5,
        totalRebuttalsRaised: 5,
        AverageQuaityScore: "94%",
        FatalCalls: 3,
        PercentageFatalCalls: '8%',
        AverageAHT: '1 min 13 secs'
    };
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(
        new Date(addDays(new Date(), -6).setHours('00', '00', '00'))
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setHours('23', '59', '59'))
    );
    const qualityScoreTrendData = [
        {
          time: '2024-09-06',
          myPerformance: 85,
          team: 78,
          process: 80,
          myPerformanceAudits: 10,
          teamAudits: 40,
          processAudits: 100,
        },
        {
          time: '2024-09-16',
          myPerformance: 87,
          team: 80,
          process: 82,
          myPerformanceAudits: 12,
          teamAudits: 42,
          processAudits: 102,
        },
        {
          time: '2024-09-04',
          myPerformance: 90,
          team: 82,
          process: 84,
          myPerformanceAudits: 15,
          teamAudits: 45,
          processAudits: 105,
        },
        {
          time: '2024-09-9',
          myPerformance: 88,
          team: 81,
          process: 83,
          myPerformanceAudits: 13,
          teamAudits: 43,
          processAudits: 103,
        },

        {
          time: '2024-09-19',
          myPerformance: 88,
          team: 81,
          process: 83,
          myPerformanceAudits: 13,
          teamAudits: 43,
          processAudits: 103,
        },
        {
          time: '2024-09-29',
          myPerformance: 88,
          team: 81,
          process: 83,
          myPerformanceAudits: 13,
          teamAudits: 43,
          processAudits: 103,
        },
        {
          time: '2024-09-3',
          myPerformance: 88,
          team: 81,
          process: 83,
          myPerformanceAudits: 13,
          teamAudits: 43,
          processAudits: 103,
        },
        {
          time: '2024-09-05',
          myPerformance: 88,
          team: 81,
          process: 83,
          myPerformanceAudits: 13,
          teamAudits: 43,
          processAudits: 103,
        },
      ];
      const timeWiseAuditData = [
        { Time_Period: '2024-09-01', NumberofAudits: 85 },
        { Time_Period: '2024-09-02', NumberofAudits: 90 },
        { Time_Period: '2024-09-03', NumberofAudits: 75 },

        { Time_Period: '2024-09-23', NumberofAudits: 56 },
        { Time_Period: '2024-09-10', NumberofAudits: 67 },
        { Time_Period: '2024-09-14', NumberofAudits: 87 },
        { Time_Period: '2024-09-20', NumberofAudits: 95 },
        { Time_Period: '2024-09-09', NumberofAudits: 67 },
        { Time_Period: '2024-09-21', NumberofAudits: 98 },
        { Time_Period: '2024-09-29', NumberofAudits: 54 },
      ];

    const TimewiseFatalAuditsTrend = [
        { Time_Period: '2024-09-01', NumberofAudits: 85 },
        { Time_Period: '2024-09-02', NumberofAudits: 90 },
        { Time_Period: '2024-09-03', NumberofAudits: 75 },
        { Time_Period: '2024-09-23', NumberofAudits: 56 },
        { Time_Period: '2024-09-10', NumberofAudits: 67 },
        { Time_Period: '2024-09-14', NumberofAudits: 87 },
        { Time_Period: '2024-09-20', NumberofAudits: 95 },
        { Time_Period: '2024-09-09', NumberofAudits: 67 },
        { Time_Period: '2024-09-21', NumberofAudits: 98 },
        { Time_Period: '2024-09-29', NumberofAudits: 54 },
      ];
    const [auditData, setAuditData] = useState(qualityScoreTrendData);
    const [timeWiseAudit, setTimeWiseAudit] = useState(timeWiseAuditData);
    const [timeWiseAuditFetal, setTimeWiseAuditFetal] = useState(TimewiseFatalAuditsTrend);
    // const [auditData, setAuditData] = useState(qualityScoreTrendData);

    useEffect(() => {
        setData(userInfo.orgRole=== 'QA Manager' ? QualityManagerData : (userInfo.orgRole=== 'Team Leader' || userInfo.orgRole === 'Customer Service Associate') ? AGENTData : QAData);
    }, [userInfo]);

      const handleDateChange = (ranges) => {
        const { startDate, endDate } = ranges;
        setStartDate(startDate);
        setEndDate(endDate);

        const filteredData = qualityScoreTrendData.filter((item) => {
            const itemDate = new Date(item.time);
            return itemDate >= startDate && itemDate <= endDate;
        });

        const filteredDataAudit = timeWiseAuditData.filter((item) => {
            const itemDate = new Date(item.Time_Period);
            return itemDate >= startDate && itemDate <= endDate;
        });

        const filteredDataFetal = TimewiseFatalAuditsTrend.filter((item) => {
            const itemDate = new Date(item.Time_Period);
            return itemDate >= startDate && itemDate <= endDate;
        });
        setAuditData(filteredData);
        setTimeWiseAudit(filteredDataAudit);
        setTimeWiseAuditFetal(filteredDataFetal);
    };

    return (
        <div className='page-wrapper no_overflow_x '>
            <div className='content pb-0'>
                <div className='row'>
                    <div className='app-container-demo'>
                        <div className='dashboard'>
                            {/* date Selector */}

                            <div
                                className='large-card title_legend_line quality_score_graph_only'
                                style={{ width: '30%' }}>
                                <div className='card-title'>
                                    <h6>Date Range Selector</h6>
                                </div>
                                <div className='card-title'>
                                    <DateRangePickerComp
                                        defaultRange={{
                                            startDate,
                                            endDate,
                                            key: 'selection',
                                        }}
                                        onDateChange={handleDateChange}
                                    />
                                </div>
                            </div>

                           
                            <div className='small-cards'>
                               {(['Quality Analyst',"QA Manager"].includes(userInfo.orgRole)) && <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            {/* card-1 */}
                                             <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    {userInfo.orgRole ===
                                                    'QA Manager'
                                                        ? 'Total Audit Count' : 'Total Audit Assigned'}{' '}
                                                    <h4 className='scalar-value'>
                                                        {userInfo.orgRole ===
                                                        'QA Manager'
                                                            ? data?.TotalAuditCount
                                                            : userInfo.orgRole ===
                                                                    'Customer Service Associate' ||
                                                                userInfo.orgRole ===
                                                                    'Team Leader'
                                                              ? data?.lob
                                                              : data?.TotalAuditCount}
                                                    </h4>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* card-3 */}
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    {userInfo.orgRole ===
                                                    'QA Manager'
                                                        ? 'Total Audits Pending'
                                                        : userInfo.orgRole ===
                                                                'Customer Service Associate' ||
                                                            userInfo.orgRole ===
                                                                'Team Leader'
                                                          ? 'Total Audits Accepted'
                                                          : 'Total Audits Pending'}{' '}
                                                    <h4 className='scalar-value'>
                                                        {userInfo.orgRole ===
                                                        'QA Manager'
                                                            ? data?.TotalAuditsPending
                                                            : userInfo.orgRole ===
                                                                    'Customer Service Associate' ||
                                                                userInfo.orgRole ===
                                                                    'Team Leader'
                                                              ? data?.totalAuditsAccepted
                                                              : data?.TotalAuditsPending}
                                                    </h4>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* card-2 */}
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    {userInfo.orgRole ===
                                                    'QA Manager'
                                                        ? 'Total Audits Accepted'
                                                        : (userInfo.orgRole ===
                                                                'Customer Service Associate' ||
                                                            userInfo.orgRole ===
                                                                'Team Leader')
                                                          ? 'Total Audits Evaluated on'
                                                          : 'Total Audits Accepted by Agent'}{' '}
                                                    <h4 className='scalar-value'>
                                                        {userInfo.orgRole ===
                                                        'QA Manager'
                                                            ? data?.TotalAuditsAccepted
                                                            : userInfo.orgRole ===
                                                                    'Customer Service Associate' ||
                                                                userInfo.orgRole ===
                                                                    'Team Leader'
                                                              ? data?.totalAuditsEvaluatedon
                                                              : data?.TotalAuditsAccepted}
                                                    </h4>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* card - 4 */}
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    {userInfo.orgRole ===
                                                    'QA Manager'
                                                        ? 'Total Rebuttals Pending'
                                                        : userInfo.orgRole ===
                                                                'Customer Service Associate' ||
                                                            userInfo.orgRole ===
                                                                'Team Leader'
                                                          ? 'Total Audits Pending'
                                                          : 'Total Rebuttals Pending'}{' '}
                                                    <h4 className='scalar-value'>
                                                        {userInfo.orgRole ===
                                                        'QA Manager'
                                                            ? data?.TotalRebuttalsPending
                                                            : userInfo.orgRole ===
                                                                    'Customer Service Associate' ||
                                                                userInfo.orgRole ===
                                                                    'Team Leader'
                                                              ? data?.totalAuditsPending
                                                              : data?.TotalRebuttalsPending}
                                                    </h4>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* card - 5 */}
                                {userInfo.orgRole !== 'QA Manager' && (
                                    <div className='small-card'>
                                        <div className='dashcard-container'>
                                            <div className='card-body-demo-demo'>
                                                <div className='scalar-title-container'>
                                                    <h3 className='scalar-title'>
                                                        {userInfo.orgRole ===
                                                            'Customer Service Associate' ||
                                                        userInfo.orgRole ===
                                                            'Team Leader'
                                                            ? 'Total Rebuttals Raised'
                                                            : 'Total Audits Pending to be Acknowledged'}{' '}
                                                        <h4 className='scalar-value'>
                                                            {userInfo.orgRole ===
                                                                'Customer Service Associate' ||
                                                            userInfo.orgRole ===
                                                                'Team Leader'
                                                                ? data?.totalRebuttalsRaised
                                                                : data?.TotalAuditsPendingtobeAcknowledged}
                                                        </h4>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* card - 6 */}
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    Average Quaity Score{' '}
                                                </h3>
                                                <h4 className='scalar-value'>
                                                    {data?.AverageQuaityScore}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* card - 7 */}
                                {!(['Quality Analyst',"QA Manager"].includes(userInfo.orgRole)) && (
                                    <div className='small-card'>
                                        <div className='dashcard-container'>
                                            <div className='card-body-demo-demo'>
                                                <div className='scalar-title-container'>
                                                    <h3 className='scalar-title'>
                                                        Fatal Calls{' '}
                                                    </h3>
                                                    <h4 className='scalar-value'>
                                                        {data?.FatalCalls}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* card - 8 */}
                                {!(['Quality Analyst',"QA Manager"].includes(userInfo.orgRole)) && <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    % Fatal Calls:{' '}
                                                </h3>
                                                <h4 className='scalar-value'>
                                                    {data?.PercentageFatalCalls}
                                                
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* card - 9 */}
                                {userInfo.orgRole !==
                                    'Customer Service Associate' &&
                                    userInfo.orgRole !== 'Team Leader' && (
                                        <div className='small-card'>
                                            <div className='dashcard-container'>
                                                <div className='card-body-demo-demo'>
                                                    <div className='scalar-title-container'>
                                                        <h3 className='scalar-title'>
                                                            Average Evaluation
                                                            Time{' '}
                                                        </h3>
                                                        <h4 className='scalar-value'>
                                                            {
                                                                data?.AverageEvaluationTime
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {/* card - 10 */}
                                <div className='small-card'>
                                    <div className='dashcard-container'>
                                        <div className='card-body-demo'>
                                            <div className='scalar-title-container'>
                                                <h3 className='scalar-title'>
                                                    {userInfo.orgRole ===
                                                    'Customer Service Associate'
                                                        ? 'Average AHT for auditted calls'
                                                        : 'Average AHT (Calls Audited)'}{' '}
                                                    <h4 className='scalar-value'>
                                                        {data?.AverageAHT}
                                                    </h4>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='large-cards'>
                                {/* <div className='large-card'> */}

                                <div className='large-card graph_tbl'>
                                    <AnalyticsTable
                                        header={'Key Performance Indicator'}
                                        height={'100%'}
                                        thead={
                                            userInfo.orgRole === 'Team Leader'
                                                ? key_performance_metrics_tl?.legends
                                                : userInfo.orgRole ===
                                                    'Customer Service Associate'
                                                  ? key_performance_metrics_agent?.legends
                                                  : userInfo.orgRole ===
                                                      'QA Manager'
                                                    ? key_performance_metrics_qamanager?.legends
                                                    : key_performance_metrics?.legends
                                        }
                                        data={
                                            userInfo.orgRole === 'Team Leader'
                                                ? key_performance_metrics_tl?.data
                                                : userInfo.orgRole ===
                                                    'Customer Service Associate'
                                                  ? key_performance_metrics_agent?.data
                                                  : userInfo.orgRole ===
                                                      'QA Manager'
                                                    ? key_performance_metrics_qamanager?.data
                                                    : key_performance_metrics?.data
                                        }
                                    />
                                </div>
                                <div className='large-card title_legend_line quality_score_graph_only'>
                                    <div className='card-title'>
                                        <h6>Quality Score Trend</h6>
                                    </div>
                                    <QualityScoreTrend data={auditData} />
                                </div>

                                <div className='large-card'>
                                    <AnalyticsTable
                                        header={'Other Performance Metrics'}
                                        height={'100%'}
                                        thead={
                                            userInfo.orgRole === 'Team Leader'
                                                ? other_performance_metrics_tl?.legends
                                                : userInfo.orgRole ===
                                                    'Customer Service Associate'
                                                  ? other_performance_metrics_agent?.legends
                                                  : userInfo.orgRole ===
                                                      'QA Manager'
                                                    ? other_performance_metrics_qamanager?.legends
                                                    : other_performance_metrics?.legends
                                        }
                                        data={
                                            userInfo.orgRole === 'Team Leader'
                                                ? other_performance_metrics_tl?.data
                                                : userInfo.orgRole ===
                                                    'Customer Service Associate'
                                                  ? other_performance_metrics_agent?.data
                                                  : userInfo.orgRole ===
                                                      'QA Manager'
                                                    ? other_performance_metrics_qamanager?.data
                                                    : other_performance_metrics?.data
                                        }
                                    />
                                </div>
                                <div className='card_title_only'>
                                    <Container
                                        title={'Surging Topics'}
                                        body={
                                            <BarChartH
                                                paddLeft={'16px'}
                                                data={surging_topic}
                                                height={400}
                                            />
                                        }
                                    />
                                </div>

                                {/* For agent */}
                                {userInfo.orgRole === 'Team Leader' && (
                                    <div className='large-card'>
                                        <AnalyticsTable
                                            header={'Agent Leaderboard'}
                                            height={'100%'}
                                            thead={
                                                userInfo.orgRole ===
                                                'Team Leader'
                                                    ? agent_leaderboard_tl?.legends
                                                    : agent_leaderboard?.legends
                                            }
                                            data={
                                                userInfo.orgRole ===
                                                'Team Leader'
                                                    ? agent_leaderboard_tl?.data
                                                    : agent_leaderboard?.data
                                            }
                                        />
                                    </div>
                                )}

                                {false && (
                                    <div className='large-card'>
                                        <AnalyticsTable
                                            header={
                                                'QA wise Average Audit Score '
                                            }
                                            height={'100%'}
                                            thead={
                                                userInfo.orgRole ===
                                                'Team Leader'
                                                    ? qa_wise_average_audit_score_tl?.legends
                                                    : qa_wise_average_audit_score?.legends
                                            }
                                            data={
                                                userInfo.orgRole ===
                                                'Team Leader'
                                                    ? qa_wise_average_audit_score_tl?.data
                                                    : qa_wise_average_audit_score?.data
                                            }
                                        />
                                    </div>
                                )}

                                {(userInfo.orgRole === 'Team Leader' ||
                                    userInfo.orgRole ===
                                        'Customer Service Associate') && (
                                    <div className='large-card'>
                                        <div className='card-title'>
                                            Time wise Audit Trend
                                        </div>
                                        <QualityLineChart
                                            data={timeWiseAudit}
                                            chartFor='Time wise Audit Trend'
                                        />
                                    </div>
                                )}

                                {(userInfo.orgRole === 'Team Leader' ||
                                    userInfo.orgRole ===
                                        'Customer Service Associate') && (
                                    <div className='large-card'>
                                        <div className='card-title'>
                                            Time wise Fatal Audits Trend
                                        </div>
                                        <QualityLineChart
                                            data={timeWiseAuditFetal}
                                            chartFor='Time wise Audit Trend'
                                        />
                                    </div>
                                )}
                            </div>

                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemoChart;
