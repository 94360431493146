const dailyAuditCountsByDepartment = [
    {
        auditDate: '2024-09-01',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 10 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 13 },
        ],
    },
    {
        auditDate: '2024-09-02',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 14 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 13 },
        ],
    },
    {
        auditDate: '2024-09-03',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 11 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 12 },
        ],
    },
    {
        auditDate: '2024-09-04',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 12 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 15 },
        ],
    },
    {
        auditDate: '2024-09-05',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 15 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 12 },
        ],
    },
];

const fatalAuditCounts = [
    {
        auditDate: '2024-09-01',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 1 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 2 },
        ],
    },
    {
        auditDate: '2024-09-02',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 2 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 1 },
        ],
    },
    {
        auditDate: '2024-09-03',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 2 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 2 },
        ],
    },
    {
        auditDate: '2024-09-04',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 0 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 2 },
        ],
    },
    {
        auditDate: '2024-09-05',
        departments: [
            { department: 'Conduent DX Higbee NA IB Health', count: 1 },
            { department: 'Conduent EBT Higbee NA IB Direct Debit', count: 0 },
        ],
    },
];

const overallSentimentData = [
    {
        department: '01-09-2024',
        positive: 14,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
    {
        department: '03-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
    {
        department: '04-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
];

const customerSentimentData = [
    {
        department: '01-09-2024',
        positive: 14,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 17,
        neutral: 6,
        negative: 4,
    },
    {
        department: '03-09-2024',
        positive: 18,
        neutral: 3,
        negative: 2,
    },
    {
        department: '04-09-2024',
        positive: 17,
        neutral: 8,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 18,
        neutral: 7,
        negative: 2,
    }
];

const agentSentimentData = [
    {
        department: '01-09-2024',
        positive: 14,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 17,
        neutral: 7,
        negative: 3,
    },
    {
        department: '03-09-2024',
        positive: 16,
        neutral: 3,
        negative: 4,
    },
    {
        department: '04-09-2024',
        positive: 17,
        neutral: 8,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 18,
        neutral: 8,
        negative: 1,
    }
];

const datewiseSentimentData = [
    {
        department: '01-09-2024',
        positive: 34,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 37,
        neutral: 7,
        negative: 3,
    },
    {
        department: '03-09-2024',
        positive: 36,
        neutral: 3,
        negative: 4,
    },
    {
        department: '04-09-2024',
        positive: 37,
        neutral: 8,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 28,
        neutral: 8,
        negative: 1,
    }
];


const key_performance_metrics = {
    data: [
        {
            metrics: 'Quality Score',
            qa: '90',
            team_qa: '95',
            overall_qa: '87',
            process: '80',
        },
        {
            metrics: 'Total Fatal Count',
            qa: '7',
            team_qa: '10',
            overall_qa: '10',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            qa: '38',
            team_qa: '56',
            overall_qa: '56',
            process: '40',
        },
        {
            metrics: 'Business Critical',
            qa: '91%',
            team_qa: '98%',
            overall_qa: '98%',
            process: '70%',
        },
        {
            metrics: 'Customer Critical',
            qa: '84%',
            team_qa: '91%',
            overall_qa: '91%',
            process: '75%',
        },
        {
            metrics: 'Compliance Critical',
            qa: '93%',
            team_qa: '97%',
            overall_qa: '97%',
            process: '80%',
        },
    ],
    legends: ['Metrics', 'QA', 'Team QA', 'Process'],
};

const key_performance_metrics_qamanager = {
    data: [
        {
            metrics: 'Quality Score',
            qa: '90',
            overall_qa: '95',
            team_agent: '70',
            process: '80',
        },
        {
            metrics: 'Total Fatal Count',
            qa: '3',
            overall_qa: '7',
            team_qa: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            qa: '20',
            overall_qa: '30',
            team_qa: '35',
            process: '40',
        },
        {
            metrics: 'Business Critical',
            qa: '80%',
            overall_qa: '70%',
            team_qa: '50%',
            process: '70%',
        },
        {
            metrics: 'Customer Critical',
            qa: '60%',
            overall_qa: '30%',
            team_qa: '55%',
            process: '75%',
        },
        {
            metrics: 'Compliance Critical',
            qa: '70%',
            overall_qa: '90%',
            team_qa: '60%',
            process: '80%',
        },
    ],
    legends: ['Metrics', 'Team QA', 'Overall QA' ,'Process'],
};

const key_performance_metrics_agent = {
    data: [
        {
            metrics: 'Quality Score',
            agent: '94',
            team_agent: '92',
            process: '96',
        },
        {
            metrics: 'Total Fatal Count',
            agnet: '3',
            team_agent: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            agent: '27',
            team_agent: '35',
            process: '48',
        },
        {
            metrics: 'Business Critical',
            agent: '86%',
            team_agent: '88%',
            process: '94%',
        },
        {
            metrics: 'Customer Critical',
            agent: '98%',
            team_agent: '96%',
            process: '99%',
        },
        {
            metrics: 'Compliance Critical',
            agent: '97%',
            team_agent: '93%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team Agent', 'Process'],
};

const key_performance_metrics_tl = {
    data: [
        {
            metrics: 'Quality Score',
            agent: '50',
            overall_agent: '70',
            team_agent: '70',
            process: '80',
        },
        {
            metrics: 'Total Fatal Count',
            agent: '4',
            overall_agent: '7',
            team_agent: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            agent: '20',
            overall_agent: '30',
            team_agent: '35',
            process: '40',
        },
        {
            metrics: 'Business Critical',
            agent: '80%',
            overall_agent: '70%',
            team_agent: '50%',
            process: '70%',
        },
        {
            metrics: 'Customer Critical',
            agent: '60%',
            overall_agent: '30%',
            team_agent: '55%',
            process: '75%',
        },
        {
            metrics: 'Compliance Critical',
            agent: '70%',
            overall_agent: '90%',
            team_agent: '60%',
            process: '80%',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team Agent', 'Overall Agent','Process'],
};

const qaDataSentiment = [
    {
        numberOfAudits: 2,
        totalScore: 21,
        averageAuditScore: 96,
        QA: 'Shekhar Suman',
    },
    {
        numberOfAudits: 3,
        totalScore: 19,
        averageAuditScore: 91,
        QA: 'Shailesh Singh',
    },
    {
        numberOfAudits: 8,
        totalScore: 27,
        averageAuditScore: 83,
        QA: 'Rohan Joshi',
    },
];

const agentDataSentiment = [
    {
        numberOfAudits: 1,
        totalScore: 14,
        averageAuditScore: 94,
        Agent: 'Surya Kumar',
    },
    {
        numberOfAudits: 2,
        totalScore: 18,
        averageAuditScore: 92,
        Agent: 'Kunal Pratap',
    },
    {
        numberOfAudits: 2,
        totalScore: 9,
        averageAuditScore: 91,
        Agent: 'Ananda Kumar',
    },
    {
        numberOfAudits: 3,
        totalScore: 12,
        averageAuditScore: 88,
        Agent: 'Shailesh Kumar',
    },
    {
        numberOfAudits: 5,
        totalScore: 16,
        averageAuditScore: 87,
        Agent: 'Sakshi Singh',
    },
];

const performance_metrics = {
    data: [
        {
            metrics: 'AHT (min)',
            team: '3.3',
            process: '4.1',
        },
        {
            metrics: 'Hold (sec)',
            team: '27.2',
            process: '29.5',
        },
        {
            metrics: 'Talk-Time',
            team: '187.8',
            process: '191.4',
        },
        {
            metrics: 'ACW (min)',
            team: '11.3',
            process: '0.9',
        },
        {
            metrics: 'FCR',
            team: '87%',
            process: '85%',
        },
    ],
    legends: ['Metrics', 'Team', 'Process'],
};

const monthlyCSATTrend = [
    {
        auditDate: 'Jul 2024',
        departments: [
            { department: 'Team', count: 92.39 },
            { department: 'Process', count: 75.27 },
        ],
    },
    {
        auditDate: 'Aug 2024',
        departments: [
            { department: 'Team', count: 83.39 },
            { department: 'Process', count: 85.27 },
        ],
    },
    {
        auditDate: 'Sep 2024',
        departments: [
            { department: 'Team', count: 73.39 },
            { department: 'Process', count: 77.27 },
        ],
    },
];

const other_performance_metrics = {
    data: [
        {
            metrics: 'Opening',
            team: '3.67',
            process: '3.63',
        },
        {
            metrics: 'Enunciation',
            team: '3.43',
            process: '3.47',
        },
        {
            metrics: 'Vocabulary',
            team: '3.05',
            process: '3.07',
        },
        {
            metrics: 'Product Knowledge',
            team: '2.01',
            process: '2.09',
        },
        {
            metrics: 'Process Knowledge',
            team: '1.87',
            process: '1.83',
        },
        {
            metrics: 'Sales Skills',
            team: '1.54',
            process: '1.72',
        },
        {
            metrics: 'Customer Service Skills',
            team: '3.16',
            process: '3.13',
        },
        {
            metrics: 'Active Listening',
            team: '3.36',
            process: '3.07',
        },
        {
            metrics: 'Empathy',
            team: '2.38',
            process: '2.27',
        },
        {
            metrics: 'Interruptions',
            team: '1.27',
            process: '1.43',
        },
        {
            metrics: 'Patience',
            team: '3',
            process: '3',
        },
        {
            metrics: 'Context Awareness',
            team: '2.13',
            process: '2.05',
        },
        {
            metrics: 'Conciseness',
            team: '2.58',
            process: '2.55',
        },
        {
            metrics: 'Repetitions',
            team: '2.75',
            process: '2.86',
        },
        {
            metrics: 'Rapport',
            team: '2.66',
            process: '2.61',
        },
        {
            metrics: 'Closing',
            team: '2.07',
            process: '1.67',
        },
    ],
    legends: ['Metrics', 'Team', 'Process'],
};
const other_performance_metrics_qamanager = {
    data: [
        {
            metrics: 'Opening',
            qa: '3.5',
            team_qa: '4',
            overall_qa: 4,
            process: '4.5',
        },
        {
            metrics: 'Enunciation',
            team: '3.43',
            process: '3.47',
        },
        {
            metrics: 'Vocabulary',
            team: '3.05',
            process: '3.07',
        },
        {
            metrics: 'Product Knowledge',
            team: '2.01',
            process: '2.09',
        },
        {
            metrics: 'Process Knowledge',
            team: '1.87',
            process: '1.83',
        },
        {
            metrics: 'Sales Skills',
            team: '1.54',
            process: '1.72',
        },
        {
            metrics: 'Customer Service Skills',
            team: '3.16',
            process: '3.13',
        },
        {
            metrics: 'Active Listening',
            team: '3.36',
            process: '3.07',
        },
        {
            metrics: 'Empathy',
            team: '2.38',
            process: '2.27',
        },
        {
            metrics: 'Interruptions',
            team: '1.27',
            process: '1.43',
        },
        {
            metrics: 'Patience',
            team: '3',
            process: '3',
        },
        {
            metrics: 'Context Awareness',
            team: '2.13',
            process: '2.05',
        },
        {
            metrics: 'Conciseness',
            team: '2.58',
            process: '2.55',
        },
        {
            metrics: 'Repetitions',
            team: '2.75',
            process: '2.86',
        },
        {
            metrics: 'Rapport',
            team: '2.66',
            process: '2.61',
        },
        {
            metrics: 'Closing',
            team: '2.07',
            process: '1.67',
        },
    ],
    legends: ['Metrics', 'Team QA', 'Overall QA' ,'Process'],
};

const other_performance_metrics_agent = {
    data: [
        {
            metrics: 'Opening',
            agent: '4.5',
            team: '4',
            process: '4.5',
        },
        {
            metrics: 'Enunciation',
            agent: '3.5',
            team: '3.43',
            process: '3.47',
        },
        {
            metrics: 'Vocabulary',
            agent: '3.5',
            team: '3.05',
            process: '3.07',
        },
        {
            metrics: 'Product Knowledge',
            agent: '2.5',
            team: '2.01',
            process: '2.09',
        },
        {
            metrics: 'Process Knowledge',
            agent: '1.5',
            team: '1.87',
            process: '1.83',
        },
        {
            metrics: 'Sales Skills',
            agent: '1.5',
            team: '1.54',
            process: '1.72',
        },
        {
            metrics: 'Customer Service Skills',
            agent: '3.5',
            team: '3.16',
            process: '3.13',
        },
        {
            metrics: 'Active Listening',
            agent: '3.5',
            team: '3.36',
            process: '3.07',
        },
        {
            metrics: 'Empathy',
            agent: '2.45',
            team: '2.38',
            process: '2.27',
        },
        {
            metrics: 'Interruptions',
            agent: '1.5',
            team: '1.27',
            process: '1.43',
        },
        {
            metrics: 'Patience',
            agent: '3.5',
            team: '3',
            process: '3',
        },
        {
            metrics: 'Context Awareness',
            agent: '2.15',
            team: '2.13',
            process: '2.05',
        },
        {
            metrics: 'Conciseness',
            agent: '2.5',
            team: '2.58',
            process: '2.55',
        },
        {
            metrics: 'Repetitions',
            agent: '2.75',
            team: '2.75',
            process: '2.86',
        },
        {
            metrics: 'Rapport',
            agent: '2.5',
            team: '2.66',
            process: '2.61',
        },
        {
            metrics: 'Closing',
            agent: '2.5',
            team: '2.07',
            process: '1.67',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
}

const other_performance_metrics_tl = {
    data: [
        {
            metrics: 'Opening',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '4.5',
        },
        {
            metrics: 'Enunciation',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '3.47',
        },
        {
            metrics: 'Vocabulary',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '3.07',
        },
        {
            metrics: 'Product Knowledge',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '2.09',
        },
        {
            metrics: 'Process Knowledge',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '1.83',
        },
        {
            metrics: 'Sales Skills',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '1.72',
        },
        {
            metrics: 'Customer Service Skills',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '3.13',
        },
        {
            metrics: 'Active Listening',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '3.07',
        },
        {
            metrics: 'Empathy',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '2.27',
        },
        {
            metrics: 'Interruptions',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '1.43',
        },
        {
            metrics: 'Patience',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '3',
        },
        {
            metrics: 'Context Awareness',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '2.05',
        },
        {
            metrics: 'Conciseness',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '2.55',
        },
        {
            metrics: 'Repetitions',
            agent: '3.5',
            team: '4',
            overall_agent: 4,
            process: '2.86',
        },
        {
            metrics: 'Rapport',
            team: '2.66',
            process: '2.61',
        },
        {
            metrics: 'Closing',
            team: '2.07',
            process: '1.67',
        },
    ],
    legends: ['Metrics', 'Agent', 'Agent Team', 'Overall Agent' ,'Process'],
};


const surging_topic = [
    {
        name: 'Healthcare Claims and Billing Automation',
        value: 75,
    },
    {
        name: 'Data Security and Compliance',
        value: 72,
    },
    {
        name: 'Remote Workforce Management',
        value: 72,
    },
    {
        name: 'Cloud Migration and Management',
        value: 71,
    },
    {
        name: 'Telehealth and Digital Patient Engagement',
        value: 71,
    },
    {
        name: 'Contact Center AI',
        value: 65,
    },
    {
        name: 'Mobility Solutions for Public Transportation',
        value: 64,
    },
    {
        name: 'Robotic Process Automation (RPA)',
        value: 63,
    },
    {
        name: 'Analytics and Business Intelligence',
        value: 62,
    },
    {
        name: 'Employee Training and Upskilling',
        value: 61,
    },
];

const agent_leaderboard = {
    data: [
        {
            agent_name: 'Sidhant',
            quality_score: '80',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        }
    ],
    legends: ['Agent Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};


const agent_leaderboard_tl = {
    data: [
        {
            agent_name: 'Sidhant',
            quality_score: '94',
            number_of_evaluations: '14',
            number_of_fatal_evaluations: '1',
        }
        , {
            agent_name: 'Sumitra',
            quality_score: '92',
            number_of_evaluations: '18',
            number_of_fatal_evaluations: '2',
        }
        , {
            agent_name: 'Shailesh',
            quality_score: '91',
            number_of_evaluations: '9',
            number_of_fatal_evaluations: '2',
        }
        , {
            agent_name: 'Kunal',
            quality_score: '88',
            number_of_evaluations: '12',
            number_of_fatal_evaluations: '3',
        }
        ,
    ],
    legends: ['Agent Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const qa_wise_average_audit_score = {
    data: [
        {
            qa_name: 'Silpa',
            quality_score: '80',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        }
    ],
    legends: ['QA Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const qa_wise_average_audit_score_tl = {
    data: [
        {
            qa_name: 'Silpa',
            quality_score: '80',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        }
    ],
    legends: ['QA Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const ConduentDataGraph = {
    agentDataSentiment,
    qaDataSentiment,
    dailyAuditCountsByDepartment,
    fatalAuditCounts,
    overallSentimentData,
    customerSentimentData,
    agentSentimentData,
    datewiseSentimentData,
    performance_metrics,
    monthlyCSATTrend,
    key_performance_metrics,
    key_performance_metrics_agent,
    key_performance_metrics_qamanager,
    key_performance_metrics_tl,
    other_performance_metrics,
    other_performance_metrics_agent,
    other_performance_metrics_tl,
    other_performance_metrics_qamanager,
    agent_leaderboard,
    agent_leaderboard_tl,
    qa_wise_average_audit_score,
    qa_wise_average_audit_score_tl,
    surging_topic,
};

export default ConduentDataGraph;
