const agentDataSentiment = [
    {
      numberOfAudits: 1,
      totalScore: 24,
      averageAuditScore: '94%',
      Agent: 'Surya Kumar',
    },
    {
      numberOfAudits: 2,
      totalScore: 22,
      averageAuditScore: '92%',
      Agent: 'Kunal Pratap',
    },
    {
      numberOfAudits: 2,
      totalScore: 19,
      averageAuditScore: '91%',
      Agent: 'Ananda Kumar',
    },
    {
      numberOfAudits: 3,
      totalScore: 21,
      averageAuditScore: '88%',
      Agent: 'Shailesh Kumar',
    },
    {
      numberOfAudits: 5,
      totalScore: 26,
      averageAuditScore: '87%',
      Agent: 'Sakshi Singh',
    }
  ];

const qaDataSentiment = [
    {
        numberOfAudits: 2,
        totalScore: 38,
        averageAuditScore: '96%',
        QA: 'Shekhar Suman',
    },
    {
        numberOfAudits: 3,
        totalScore: 36,
        averageAuditScore: '91%',
        QA: 'Anand Rao',
    },
    {
        numberOfAudits: 8,
        totalScore: 38,
        averageAuditScore: '83%',
        QA: 'Rohan Joshi',
    },
];

const dailyAuditCountsByDepartment = [
    {
        auditDate: '2024-09-01',
        departments: [
            { department: 'Patient Support', count: 2 },
            { department: 'Device Delivery', count: 3 },
        ],
    },
    {
        auditDate: '2024-09-02',
        departments: [
            { department: 'Patient Support', count: 3 },
            { department: 'Device Delivery', count: 4 },
        ],
    },
    {
        auditDate: '2024-09-03',
        departments: [
            { department: 'Patient Support', count: 2 },
            { department: 'Device Delivery', count: 4 },
        ],
    },
    {
        auditDate: '2024-09-04',
        departments: [
            { department: 'Patient Support', count: 3 },
            { department: 'Device Delivery', count: 5 },
        ],
    },
    {
        auditDate: '2024-09-05',
        departments: [
            { department: 'Patient Support', count: 2 },
            { department: 'Device Delivery', count: 2 },
        ],
    },
];

const monthlyCSATTrend = [
    {
        auditDate: 'Jul 2024',
        departments: [
            { department: 'Team', count: 92.39 },
            { department: 'Process', count: 75.27 },
        ],
    },
    {
        auditDate: 'Aug 2024',
        departments: [
            { department: 'Team', count: 83.39 },
            { department: 'Process', count: 85.27 },
        ],
    },
    {
        auditDate: 'Sep 2024',
        departments: [
            { department: 'Team', count: 73.39 },
            { department: 'Process', count: 77.27 },
        ],
    },
];

// {
//     data: [
//         {
//             x: 'Jan 2024',
//             team: 82.48,
//             process: 83.39,
//         },
//         {
//             x: 'Mar 2024',
//             team: 82.54,
//             process: 83.65,
//         },
//         {
//             x: 'Apr 2024',
//             team: 82.43,
//             process: 84.17,
//         },
//         {
//             x: 'May 2024',
//             team: 82.73,
//             process: 86.93,
//         },
//         {
//             x: 'Jun 2024',
//             team: 82.8,
//             process: 83.93,
//         },
//         {
//             x: 'Jul 2024',
//             team: 83.39,
//             process: 85.27,
//         },
//     ],
//     legends: ['Metrics', 'Team', 'Process'],
// },

const fatalAuditCounts = [
    {
        auditDate: '2024-09-01',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-02',
        departments: [
            { department: 'Patient Support', count: 1 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-03',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-04',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-05',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
];

const overallSentimentData = [
    {
        department: '01-09-2024',
        positive: 14,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
    {
        department: '03-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
    {
        department: '04-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 9,
        neutral: 5,
        negative: 2,
    },
];

const key_performance_metrics = {
    data: [
        {
            "metrics": "Quality Score",
            "self": "92%",
            "team_qa": "90%",
            "overall_qa": "87%",
            "process": "91%"
        },
        {
            "metrics": "Total Fatal Count",
            "self": "3",
            "team_qa": "7",
            "overall_qa": "10",
            "process": "10"
        },
        {
            "metrics": "Total Non-Fatal Count",
            "self": "27",
            "team_qa": "38",
            "overall_qa": "56",
            "process": "56"
        },
        {
            "metrics": "Business Critical",
            "self": "86%",
            "team_qa": "91%",
            "overall_qa": "98%",
            "process": "98%"
        },
        {
            "metrics": "Customer Critical",
            "self": "95%",
            "team_qa": "84%",
            "overall_qa": "91%",
            "process": "91%"
        },
        {
            "metrics": "Compliance Critical",
            "self": "98%",
            "team_qa": "93%",
            "overall_qa": "97%",
            "process": "97%"
        } 
    ],
    legends: ['Metrics', 'Self', 'Team QA', 'Process'],
};

const key_performance_metrics_qamanager = {
    data: [
        {
          metrics: 'Quality Score',
          qa: '90%',
          overall_qa: '95%',
          team: '90%',
          process: '95%',
        },
        {
          metrics: 'Total Fatal Count',
          qa: '4',
          overall_qa: '7',
          team: '7',
          process: '10',
        },
        {
          metrics: 'Total Non-Fatal Count',
          qa: '20',
          overall_qa: '30',
          team: '38',
          process: '56',
        },
        {
          metrics: 'Business Critical',
          qa: '80%',
          overall_qa: '70%',
          team: '91%',
          process: '98%',
        },
        {
          metrics: 'Customer Critical',
          qa: '60%',
          overall_qa: '30%',
          team: '84%',
          process: '91%',
        },
        {
          metrics: 'Compliance Critical',
          qa: '70%',
          overall_qa: '90%',
          team: '93%',
          process: '97%',
        }
      ],      
    legends: ['Metrics', 'Team' ,'Process'],
};

const key_performance_metrics_agent = {
    data: [
        {
            metrics: 'Quality Score',
            agent: '94%',
            team: '92%',
            process: '96%',
        },
        {
            metrics: 'Total Fatal Count',
            agent: '3',
            team: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            agent: '27',
            team: '35',
            process: '48',
        },
        {
            metrics: 'Business Critical',
            agent: '86%',
            team: '88%',
            process: '94%',
        },
        {
            metrics: 'Customer Critical',
            agent: '98%',
            team: '96%',
            process: '99%',
        },
        {
            metrics: 'Compliance Critical',
            agent: '97%',
            team: '93%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};

const key_performance_metrics_tl = {
    data: [
        {
            metrics: 'Quality Score',
            agent: '94%',
            overall_agent: '70',
            team: '92%',
            process: '96%',
        },
        {
            metrics: 'Total Fatal Count',
            agent: '3',
            overall_agent: '7',
            team: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            agent: '27',
            overall_agent: '30',
            team: '35',
            process: '48',
        },
        {
            metrics: 'Business Critical',
            agent: '86%',
            overall_agent: '70%',
            team: '88%',
            process: '94%',
        },
        {
            metrics: 'Customer Critical',
            agent: '98%',
            overall_agent: '30%',
            team: '96%',
            process: '99%',
        },
        {
            metrics: 'Compliance Critical',
            agent: '97%',
            overall_agent: '90%',
            team: '93%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};

const performance_metrics = {
    data: [
        {
            metrics: 'AHT (min)',
            team: '3.3',
            process: '4.1',
        },
        {
            metrics: 'Hold (sec)',
            team: '27.2',
            process: '29.5',
        },
        {
            metrics: 'Talk-Time',
            team: '187.8',
            process: '191.4',
        },
        {
            metrics: 'ACW (min)',
            team: '11.3',
            process: '0.9',
        },
        {
            metrics: 'FCR',
            team: '87%',
            process: '85%',
        },
    ],
    legends: ['Metrics', 'Team', 'Process'],
};
const customerSentimentData = [
    {
        department: '01-09-2024',
        positive: 14,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 17,
        neutral: 6,
        negative: 4,
    },
    {
        department: '03-09-2024',
        positive: 18,
        neutral: 3,
        negative: 2,
    },
    {
        department: '04-09-2024',
        positive: 17,
        neutral: 8,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 18,
        neutral: 7,
        negative: 2,
    }
];

const agentSentimentData = [
    {
        department: '01-09-2024',
        positive: 14,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 17,
        neutral: 7,
        negative: 3,
    },
    {
        department: '03-09-2024',
        positive: 16,
        neutral: 3,
        negative: 4,
    },
    {
        department: '04-09-2024',
        positive: 17,
        neutral: 8,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 18,
        neutral: 8,
        negative: 1,
    }
];

const datewiseSentimentData = [
    {
        department: '01-09-2024',
        positive: 34,
        neutral: 6,
        negative: 3,
    },
    {
        department: '02-09-2024',
        positive: 37,
        neutral: 7,
        negative: 3,
    },
    {
        department: '03-09-2024',
        positive: 36,
        neutral: 3,
        negative: 4,
    },
    {
        department: '04-09-2024',
        positive: 37,
        neutral: 8,
        negative: 2,
    },
    {
        department: '05-09-2024',
        positive: 28,
        neutral: 8,
        negative: 1,
    }
];

const other_performance_metrics = {
    data:[
        {
          metrics: 'Opening',
          qa: 3.5,
          team: 4.0,
          process: 4.5,
        },
        {
          metrics: 'Vocabulary',
          qa: 3.2,
          team: 3.1,
          process: 3.1,
        },
        {
          metrics: 'Product Knowledge',
          qa: 2.5,
          team: 2.0,
          process: 2.1,
        },
        {
          metrics: 'Process Knowledge',
          qa: 1.5,
          team: 1.9,
          process: 1.8,
        },
        {
          metrics: 'Sales Skills',
          qa: 1.5,
          team: 1.5,
          process: 1.7,
        },
        {
          metrics: 'Customer Service Skills',
          qa: 3.5,
          team: 3.2,
          process: 3.1,
        },
        {
          metrics: 'Active Listening',
          qa: 3.5,
          team: 3.4,
          process: 3.1,
        }
      ],
    legends: ['Metrics', 'QA', 'Team', 'Process'],
}

const other_performance_metrics_qamanager = {
    data: [
        {
          metrics: 'Opening',
          qa: 3.5,
          team: 4.0,
          overall_qa: 4.0,
          process: 4.5,
        },
        {
          metrics: 'Vocabulary',
          qa: 3.2,
          team: 3.1,
          overall_qa: 3.4,
          process: 3.1,
        },
        {
          metrics: 'Product Knowledge',
          qa: 2.5,
          team: 2.1,
          overall_qa: 2.4,
          process: 2.1,
        },
        {
          metrics: 'Process Knowledge',
          qa: 1.5,
          team: 1.4,
          overall_qa: 1.5,
          process: 1.8,
        },
        {
          metrics: 'Sales Skills',
          qa: 1.5,
          team: 1.1,
          overall_qa: 1.6,
          process: 1.7,
        },
        {
          metrics: 'Customer Service Skills',
          qa: 3.5,
          team: 4.0,
          overall_qa: 4.2,
          process: 3.1,
        },
        {
          metrics: 'Active Listening',
          qa: 3.5,
          team: 3.4,
          overall_qa: 3.0,
          process: 3.1,
        }
      ]
      ,
    legends: ['Metrics', 'Team', 'Process'],
};

const other_performance_metrics_agent = {
    data:[
        {
          metrics: 'Opening',
          agent: 3.5,
          team: 4.0,
          process: 4.5,
        },
        {
          metrics: 'Vocabulary',
          agent: 3.2,
          team: 3.1,
          process: 3.1,
        },
        {
          metrics: 'Product Knowledge',
          agent: 2.5,
          team: 2.1,
          process: 2.1,
        },
        {
          metrics: 'Process Knowledge',
          agent: 1.5,
          team: 1.4,
          process: 1.8,
        },
        {
          metrics: 'Sales Skills',
          agent: 1.5,
          team: 1.1,
          process: 1.7,
        },
        {
          metrics: 'Customer Service Skills',
          agent: 3.5,
          team: 4.0,
          process: 3.1,
        },
        {
          metrics: 'Active Listening',
          agent: 3.5,
          team: 3.4,
          process: 3.1,
        }
      ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
}

const other_performance_metrics_tl = {
    data: [
        {
          metrics: 'Opening',
          agent: 3.5,
          team: 4.0,
          overall_agent: 4.0,
          process: 4.5,
        },
        {
          metrics: 'Vocabulary',
          agent: 3.2,
          team: 3.1,
          overall_agent: 3.4,
          process: 3.1,
        },
        {
          metrics: 'Product Knowledge',
          agent: 2.5,
          team: 2.1,
          overall_agent: 2.4,
          process: 2.1,
        },
        {
          metrics: 'Process Knowledge',
          agent: 1.5,
          team: 1.4,
          overall_agent: 1.5,
          process: 1.8,
        },
        {
          metrics: 'Sales Skills',
          agent: 1.5,
          team: 1.1,
          overall_agent: 1.6,
          process: 1.7,
        },
        {
          metrics: 'Customer Service Skills',
          agent: 3.5,
          team: 4.0,
          overall_agent: 4.2,
          process: 3.1,
        },
        {
          metrics: 'Active Listening',
          agent: 3.5,
          team: 3.4,
          overall_agent: 3.0,
          process: 3.1,
        }
      ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};


const agent_leaderboard = {
    data: [
        {
            agent_name: 'Sidhant',
            quality_score: '80%',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        }
    ],
    legends: ['Agent Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const agent_leaderboard_tl = {
    data: [
        {
            agent_name: 'Sidhant',
            quality_score: '94%',
            number_of_evaluations: '24',
            number_of_fatal_evaluations: '1',
        }
        , {
            agent_name: 'Sumitra',
            quality_score: '92%',
            number_of_evaluations: '22',
            number_of_fatal_evaluations: '2',
        }
        , {
            agent_name: 'Shekhar',
            quality_score: '91%',
            number_of_evaluations: '19',
            number_of_fatal_evaluations: '2',
        }
        , {
            agent_name: 'Kunal',
            quality_score: '88%',
            number_of_evaluations: '21',
            number_of_fatal_evaluations: '3',
        }
        ,
        , {
            agent_name: 'Shailesh',
            quality_score: '87%',
            number_of_evaluations: '26',
            number_of_fatal_evaluations: '5',
        }
        ,
    ],
    legends: ['Agent Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const qa_wise_average_audit_score = {
    data: [
        {
            qa_name: 'Silpa',
            quality_score: '80%',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        }
    ],
    legends: ['QA Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const qa_wise_average_audit_score_tl = {
    data: [
        {
            qa_name: 'Silpa',
            quality_score: '80%',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        }
    ],
    legends: ['QA Name', 'Quality Score', 'Number of Evaluations', 'Number of Fatal Evaluations'],
};

const surging_topic = [
    {
        name: 'Home Sleep Apnea Testing (HSAT)',
        value: 75,
    },
    {
        name: 'Mobile Cardiac Telemetry (MCT)',
        value: 72,
    },
    {
        name: 'Overnight Pulse Oximetry (POX)',
        value: 72,
    },
    {
        name: 'Real-time Diagnostics',
        value: 71,
    },
    {
        name: 'Remote Patient Monitoring',
        value: 71,
    },
    {
        name: 'EEG Insomnia Testing',
        value: 65,
    },
    {
        name: 'Billing and Reimbursement Issues',
        value: 64,
    },
    {
        name: 'Integration with Telehealth',
        value: 63,
    },
    {
        name: 'Accurate Diagnostic Reports',
        value: 62,
    },
    {
        name: 'User-Friendly Testing Devices',
        value: 61,
    },
];

const VirtuOxDataGraph = {
    agentDataSentiment,
    qaDataSentiment,
    dailyAuditCountsByDepartment,
    fatalAuditCounts,
    overallSentimentData,
    customerSentimentData,
    agentSentimentData,
    datewiseSentimentData,
    performance_metrics,
    monthlyCSATTrend,
    key_performance_metrics,
    key_performance_metrics_agent,
    key_performance_metrics_qamanager,
    key_performance_metrics_tl,
    other_performance_metrics,
    other_performance_metrics_agent,
    other_performance_metrics_tl,
    other_performance_metrics_qamanager,
    agent_leaderboard,
    agent_leaderboard_tl,
    qa_wise_average_audit_score,
    qa_wise_average_audit_score,
    qa_wise_average_audit_score_tl,
    surging_topic,
};

export default VirtuOxDataGraph;
